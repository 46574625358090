import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import React from 'react';

const DownloadGDPR = ({ gdprUrl }: { gdprUrl: string }) => {
  if (!gdprUrl) return null;
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = gdprUrl;
    link.download = 'download'; // or extract filename from imageurl
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="flex flex-col items-center justify-center">
      <button
        onClick={handleDownload}
        className="download-button gap-3 flex flex-wrap  hover:scale-110 ease-in-out duration-300 hover:underline"
      >
        <PictureAsPdfIcon sx={{ color: '#675CFF' }} />
        <p className="text-sm hover:underline">Rekisteriseloste</p>
      </button>
    </div>
  );
};

export default DownloadGDPR;
