import React, { createContext, useContext, ReactNode } from 'react';
import {
  ToastContainer,
  toast,
  ToastOptions,
  ToastContent,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ToastContextProps {
  showToast: (message: ToastContent, options?: ToastOptions) => void;
}

export const ToastContext = createContext<ToastContextProps>({
  showToast: (message: ToastContent, options?: ToastOptions) =>
    toast(message, options),
});

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  return (
    <ToastContext.Provider value={{ showToast: toast }}>
      <ToastContainer />
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
