import React from 'react';
import Modal from '../../../../components/modal/modal';
import UpdatePaymentMethod from '../components/updatePaymentMethod';
import { CardContent, Typography, Button, Card } from '@mui/material';
import PaymentCard from '../components/paymentCard';
const Profile = ({
  billingAddress,
  paymentMethod,
  updateCard,
  openModal,
  setOpenModal,
}: any) => {
  if (!billingAddress) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  const { shipping } = billingAddress;
  if (!shipping) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  const { city, line1, postal_code } = shipping.address;

  return (
    <div className="flex justify-center items-center max-w-5xl  ">
      <Modal
        show={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        title="Update Payment Method"
      >
        <UpdatePaymentMethod
          paymentMethod={paymentMethod}
          updateCard={updateCard}
        />
      </Modal>

      <Card className="w-full overflow-hidden flex">
        <CardContent className="w-full md:w-1/2 p-5">
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            marginBottom={2}
          >
            My Details
          </Typography>
          <div className="flex flex-col p-3 gap-3">
            <Typography component="div" variant="body1" color="textSecondary">
              <strong>Name:</strong> {shipping.name}
            </Typography>
            <Typography component="div" variant="body1" color="textSecondary">
              <strong>Email:</strong> {billingAddress.email}
            </Typography>
            <Typography component="div" variant="body1" color="textSecondary">
              <strong>Address:</strong> {line1}
            </Typography>
            <Typography component="div" variant="body1" color="textSecondary">
              <strong>City:</strong> {city}
            </Typography>
            <Typography component="div" variant="body1" color="textSecondary">
              <strong>Postal Code:</strong> {postal_code}
            </Typography>
          </div>
        </CardContent>
        <div className="flex flex-col w-full md:w-1/2 p-5  gap-4">
          <PaymentCard paymentMethod={paymentMethod} />
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenModal(!openModal)}
            className="m-3"
          >
            Update Card
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Profile;
