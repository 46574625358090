import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TeltonikaSetupGuide from '../guides/configureRUT';
import Modal from '../../components/modal/modal';
import { NoSites } from './components/noSites';
import { SiteRow } from './components/siteRow';

export default function CollapsibleTable({
  userSites,
  setHandleUpdate,
  handleUpdate,
  errorCameras,
}: {
  userSites: any[];
  setHandleUpdate: any;
  handleUpdate: number;
  errorCameras: string[];
}) {
  const [rutGuide, setRutGuide] = React.useState(false);
  const [rut, setRut] = React.useState(false);
  if (!userSites.length) {
    return <NoSites />;
  }
  console.log('userSites', userSites);
  const rows = userSites.map((site) => ({
    name: site.basicInfo.name,
    address: `${site.basicInfo.street}, ${site.basicInfo.city}, ${site.basicInfo.postalCode}`,
    contactPerson: site.contactPerson.name,
    phone: site.contactPerson.phone,
    cameras: site.cameras,
    users: site.remoteUsers,
    x09: site.x09,
    x08: site.x08,
    rutAddress: site.rutAddress,
    subscription: site.subscription,
    targetID: site.targetID,
    siteID: site.id,
    gdprUrl: site.GDPR,
  }));

  const handeRutGuide = (rut: any) => {
    setRut(rut);
    setRutGuide(!rutGuide);
  };

  return (
    <>
      <Modal show={rutGuide} onClose={() => setRutGuide(false)}>
        <TeltonikaSetupGuide rut={rut} />
      </Modal>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow
              sx={{
                '& > *': {
                  borderBottom: 'unset',
                  backgroundColor: '#43687e',
                  padding: '10px',
                },
              }}
            >
              <TableCell />
              <TableCell sx={{ color: 'white' }} align="left">
                Kohde
              </TableCell>
              <TableCell sx={{ color: 'white' }} align="center">
                Osoite
              </TableCell>
              <TableCell sx={{ color: 'white' }} align="right">
                Yhteyshenkilö
              </TableCell>
              <TableCell sx={{ color: 'white' }} align="right">
                Puhelinnumero
              </TableCell>
              <TableCell sx={{ color: 'white' }} align="center">
                Konfigurointi ohje
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="bg-stone-200">
            {rows.map((row, index) => (
              <SiteRow
                key={index}
                site={row}
                setHandleUpdate={setHandleUpdate}
                handleUpdate={handleUpdate}
                handeRutGuide={handeRutGuide}
                errorCameras={errorCameras}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
