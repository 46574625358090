import React from 'react';
import { Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Cameras from './cameras';
import Remoteusers from './remoteusers';
import { convertTimestamp } from '../functions/convertTime';
import DownloadRut from '../../cameraManagement/downloadRut';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { useApi } from '../../../context/ApiCalls';
import DownloadGDPR from './downloadGDPR';
export function SiteRow({
  site,
  handleUpdate,
  setHandleUpdate,
  handeRutGuide,
  errorCameras,
}: {
  site: any;
  handleUpdate: number;
  setHandleUpdate: any;
  handeRutGuide: any;
  errorCameras: string[];
}) {
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const { deleteCamera, deleteRemoteUser } = useApi();

  const handleDelete = async ({ id, type }: { id: number; type: string }) => {
    if (type === 'camera') {
      const response = await deleteCamera({
        camera: id,
        sub: site.subscription,
      });
      if (response.status === 200) {
        // refresh data
        setHandleUpdate(handleUpdate + 1);
      } else {
        // error
      }
    } else if (type === 'user') {
      const response = await deleteRemoteUser(id);
      if (response.status === 200) {
        // refresh data
        setHandleUpdate(handleUpdate + 1);
      } else {
        // error
      }
    }
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': {
            borderBottom: 'unset',
            backgroundColor: '#E1EFFF',
            color: 'black',
            padding: '10px',
          },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ fontWeight: 'bold' }} component="th" scope="row">
          {site.name}
        </TableCell>
        <TableCell sx={{ fontWeight: 'bold' }} align="right">
          {site.address}
        </TableCell>
        <TableCell align="right">{site.contactPerson}</TableCell>
        <TableCell align="right">{site.phone}</TableCell>
        <TableCell align="right">
          <div className="flex justify-between items-center">
            <DownloadRut imageurl={site} />
            <DownloadGDPR gdprUrl={site.gdprUrl} />
            <HelpCenterIcon
              onClick={() => handeRutGuide(site)}
              className="mx-2 color-white cursor-pointer hover:scale-110 ease-in-out duration-300"
              sx={{ fontSize: 30, color: '#675CFF' }}
            />
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {/* Kamerat */}
            <Cameras
              site={site}
              edit={edit}
              setEdit={setEdit}
              handleDelete={handleDelete}
              convertTimestamp={convertTimestamp}
              handleUpdate={handleUpdate}
              setHandleUpdate={setHandleUpdate}
              errorCameras={errorCameras}
            />

            {/* Etäkäyttäjät */}
            <Remoteusers
              site={site}
              edit={edit}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              setHandleUpdate={setHandleUpdate}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
