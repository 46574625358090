import React from 'react';
// Import icon from a library if you are using one, for example:
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const DownloadRut = ({ imageurl }: any) => {
  const handleDownloadx09 = () => {
    const link = document.createElement('a');
    link.href = imageurl.x09;
    link.download = 'download'; // or extract filename from imageurl
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleDownloadx08 = () => {
    const link = document.createElement('a');
    link.href = imageurl.x08;
    link.download = 'download'; // or extract filename from imageurl
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <button
        onClick={handleDownloadx09}
        className="download-button gap-3 flex flex-wrap  hover:scale-110 ease-in-out duration-300 hover:underline"
      >
        <CloudDownloadIcon sx={{ color: '#675CFF' }} />
        <p className="text-sm hover:underline">RUT X09 config</p>
      </button>
      <button
        onClick={handleDownloadx08}
        className="download-button gap-3 flex flex-wrap  hover:scale-110 ease-in-out duration-300"
      >
        <CloudDownloadIcon sx={{ color: '#675CFF' }} />
        <p className="text-sm  hover:underline">RUT X08 config</p>
      </button>
    </div>
  );
};

export default DownloadRut;
