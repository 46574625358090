import React, { useState } from 'react';
import { Input } from '@mui/material';
import { useApi } from '../../../context/ApiCalls';

const AddNewUser = ({
  site,
  closeModal,
  handleUpdate,
  setHandleUpdate,
}: any) => {
  const [remoteUser, setRemoteUser] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const { addRemoteUser } = useApi();

  const handleNewUser = async () => {
    const response = await addRemoteUser({
      remoteUser: remoteUser,
      siteID: site.siteID,
    });
    if (response.status === 200) {
      setHandleUpdate(handleUpdate + 1);
    }
    closeModal();
  };

  return (
    <div>
      <h1>Lisää uusi käyttäjä</h1>
      <div className="flex justify-row gap-4 p-4">
        <Input
          type="text"
          placeholder="Nimi"
          onChange={(e) =>
            setRemoteUser({ ...remoteUser, name: e.target.value })
          }
        />
        <Input
          type="text"
          placeholder="Sähköposti"
          onChange={(e) =>
            setRemoteUser({ ...remoteUser, email: e.target.value })
          }
        />
        <Input
          type="text"
          placeholder="Puhelin"
          onChange={(e) =>
            setRemoteUser({ ...remoteUser, phone: e.target.value })
          }
        />
      </div>
      <div className="flex justify-end">
        <button className="justify-end" onClick={handleNewUser}>
          Lisää käyttäjä
        </button>
      </div>
    </div>
  );
};

export default AddNewUser;
