import React, { useState, useEffect } from 'react';
import { Video, VideoOff } from 'lucide-react';
import { Input, Typography } from '@material-tailwind/react';

const CameraAmount = ({
  customer,
  updateCustomer,
  setCameras,
  submitCustomer,
}: any) => {
  const [localCameras, setLocalCameras] = useState(customer.cameras);
  useEffect(() => {
    setCameras(localCameras);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localCameras]);
  const handleAddCamera = () => {
    // Luodaan uusi kameraobjekti
    const newCamera = {
      name: '', // Alustetaan tyhjällä nimellä
      location: '', // Alustetaan tyhjällä sijainnilla
    };
    // Lisätään uusi kamera localCameras-taulukkoon
    setLocalCameras([...localCameras, newCamera]);
  };
  const handleRemoveCamera = (index: any) => {
    setLocalCameras(localCameras.filter((_: any, i: any) => i !== index));
  };

  const handleNameChange = (index: any, value: any) => {
    const newCameras = [...localCameras];
    newCameras[index].name = value;
    setLocalCameras(newCameras);
  };

  const handleLocationChange = (index: any, value: any) => {
    const newCameras = [...localCameras];
    newCameras[index].location = value;
    setLocalCameras(newCameras);
  };

  return (
    <div className="bg-primaryBlue p-4 rounded-md shadow-md mb-4">
      <Typography placeholder={''} variant="h4" color="white" className="mb-4">
        Cameras
      </Typography>

      {localCameras.map((camera: any, index: any) => (
        <div key={index} className="flex items-center mb-4 gap-6">
          <Input
            required
            type="text"
            value={camera.name}
            crossOrigin={true}
            onChange={(e) => handleNameChange(index, e.target.value)}
            label="Camera name"
            color="white"
          />
          <Input
            type="text"
            crossOrigin={true}
            value={camera.location}
            onChange={(e) => handleLocationChange(index, e.target.value)}
            label="Camera location"
            color="white"
          />
          <VideoOff
            color={'red'}
            size={48}
            className="cursor-pointer"
            onClick={() => handleRemoveCamera(index)}
          />
          <p className="text-white ml-2">Delete</p>
        </div>
      ))}
      <div
        className="flex items-center cursor-pointer mb-4 group" // Lisätty "group" luokka
        onClick={handleAddCamera}
      >
        <div className="addCamIcon transition-transform transform group-hover:scale-110 ">
          <Video className=" text-white " size={28} />
        </div>
        <span className="text-white ml-2">Add new camera</span>
      </div>
    </div>
  );
};

export default CameraAmount;
