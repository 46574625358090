const TableRow = ({ name, location }: any) => (
  <tr className="border-b">
    <td className="py-2 px-3 text-left">{name}</td>
    <td className="py-2 px-3">{location}</td>
  </tr>
);

export const CamerasTable = ({ customer, errors, showErrorIndicator }: any) => {
  return (
    <div className="mb-6">
      <h3 className="text-lg font-semibold mb-2">
        Cameras {showErrorIndicator('cameras')}
        {errors.cameras && (
          <span className="text-red-500 text-sm"> {errors.cameras}</span>
        )}
      </h3>

      <table className="min-w-full table-auto">
        <tbody>
          {customer.cameras.map((camera: any, index: any) => (
            <tr key={index} className="border-b">
              <td className="py-2 px-3">{index + 1}</td>
              <td className="py-2 px-3">{camera.name}</td>
              <td className="py-2 px-3">{camera.location}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const RemoteUsersTable = ({
  customer,
  errors,
  showErrorIndicator,
}: any) => {
  return (
    <div className="mb-6">
      <h3 className="text-lg font-semibold mb-2">
        Remote users {showErrorIndicator('remoteUsers')}
        {errors.remoteUsers && (
          <span className="text-red-500 text-sm">{errors.remoteUsers}</span>
        )}
      </h3>
      <table className="min-w-full table-auto">
        <tbody>
          {customer.remoteUsers.map((user: any, index: any) => (
            <tr key={index} className="border-b">
              <td className="py-2 px-3">{index + 1}</td>
              <td className="py-2 px-3">{user.name}</td>
              <td className="py-2 px-3">{user.email}</td>
              <td className="py-2 px-3">{user.phone}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const UserInformationTable = ({
  customer,
  errors,
  showErrorIndicator,
}: any) => {
  return (
    <table className="min-w-full table-auto mb-6">
      <tbody>
        <tr className="border-b">
          <th className="text-left text-white py-2 px-3">
            Name: {showErrorIndicator('name')}{' '}
            {errors.name && (
              <span className="text-red-500 text-sm">{errors.name}</span>
            )}
          </th>
          <td className="py-2 px-3 text-white">{customer.basicInfo.name}</td>
        </tr>
        <TableRow name="Street" location={customer.basicInfo.street} />
        <TableRow name="City" location={customer.basicInfo.city} />
        <TableRow name="Postal code" location={customer.basicInfo.postalCode} />
      </tbody>
    </table>
  );
};

export const ContactInformationTable = ({ customer }: any) => {
  return (
    <table className="min-w-full table-auto mb-6 text-white">
      <tbody>
        <TableRow
          name="Contact person name"
          location={customer.contactPerson.name}
        />
        <TableRow name="Email" location={customer.contactPerson.email} />
        <TableRow name="Phone" location={customer.contactPerson.phone} />
      </tbody>
    </table>
  );
};
