import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { LogOut } from 'lucide-react';
import logoImage from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import AddHomeIcon from '@mui/icons-material/AddHome';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HomeIcon from '@mui/icons-material/Home';

function TopBar() {
  const { logout, currentUser, verifiedUser } = useAuth();
  const [showMenu, setShowMenu] = useState(false);
  const dashboardHoverTimer = useRef(null);
  const navigate = useNavigate();
  const handleDashboardEnter = () => {
    if (dashboardHoverTimer.current) {
      clearTimeout(dashboardHoverTimer.current);
    }
    dashboardHoverTimer.current = null;
    setShowMenu(true);
  };

  const handleDashboardLeave = () => {
    dashboardHoverTimer.current = setTimeout(() => {
      setShowMenu(false);
    }, 1000); // 1 sekunnin viive
  };

  useEffect(() => {
    return () => {
      if (dashboardHoverTimer.current) {
        clearTimeout(dashboardHoverTimer.current);
      }
    };
  }, []);

  function handleLogout() {
    logout();
    navigate('/login');
  }
  const dashboardLinks = [
    { to: '/dashboard', text: 'My sites', icon: <MapsHomeWorkIcon /> },
    { to: '/addNewCustomer', text: 'New site', icon: <AddHomeIcon /> },
    { to: '/guides', text: 'Guides', icon: <AutoStoriesIcon /> },
    { to: '/myAccount', text: 'My account', icon: <ManageAccountsIcon /> },
  ];

  const handleDashboardClick = ({ to, text, icon }, index) => {
    return (
      <Link
        key={index}
        to={to}
        className="flex px-4 py-2  rounded-b-xl  flex-row items-center gap-2 hover:scale-110 ease-in-out duration-300"
        onClick={() => setShowMenu(false)}
      >
        {icon}
        <p>{text}</p>
      </Link>
    );
  };

  return (
    <div className="bg-primaryBlue text-white font-bold sticky    top-0 flex justify-evenly px-10 py-2 z-10">
      <Link to="/home" className="  sm:h-14">
        <img src={logoImage} alt="Logo" className="  sm:h-14" />
      </Link>
      <div className="flex items-center gap-4 ">
        <Link
          to="/home"
          className="flex flex-row gap-1 items-center hover:scale-125 ease-in-out duration-300"
        >
          <HomeIcon sx={{ fontSize: 25 }} className="hover:scale-125" />
          <p> Home </p>
        </Link>
        <Link to="/about" className="  ml-4">
          About
        </Link>
        {currentUser && verifiedUser && (
          <div
            className="relative "
            onMouseOver={handleDashboardEnter}
            onMouseLeave={handleDashboardLeave}
          >
            <button className=" ml-4 hover:bg-slate-700 items-center">
              Dashboard <ArrowDropDownIcon />{' '}
            </button>
            {showMenu && (
              <div className="absolute bg-white rounded-xl text-primaryBlue  shadow-md mt-2 py-2 w-40">
                {dashboardLinks.map((link, index) =>
                  handleDashboardClick(link, index)
                )}
              </div>
            )}
          </div>
        )}
        <Link to="/contact" className="  ml-4">
          Contact{' '}
        </Link>
      </div>
      {currentUser && (
        <div className="flex items-center ">
          <p className="text-grey-900 mr-4 text-sm">{currentUser.email}</p>
          <button
            onClick={handleLogout}
            className="flex items-center hover:scale-110 ease-in-out duration-300 "
          >
            <LogOut size={24} className="mr-2 " />
            Logout
          </button>
        </div>
      )}
      {!currentUser && (
        <div className="flex items-center ">
          <Link to="/login" className="-gray-900 mr-4 ">
            Login
          </Link>
          <Link to="/register" className="">
            Register
          </Link>
        </div>
      )}
    </div>
  );
}

export default TopBar;
