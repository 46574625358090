export function convertTimestamp(timestamp: any) {
  const date = new Date(timestamp);

  // Muotoile päivämäärä ja aika halutussa muodossa
  const formattedDate =
    [
      date.getDate().toString().padStart(2, '0'), // Päivä (DD)
      (date.getMonth() + 1).toString().padStart(2, '0'), // Kuukausi (MM), huomaa että getMonth() palauttaa kuukaudet 0-11
      date.getFullYear().toString(), // Vuosi (YY)
    ].join('.') +
    ' ' +
    'klo' +
    ' ' +
    [
      date.getHours().toString().padStart(2, '0'), // Tunnit (HH)
      date.getMinutes().toString().padStart(2, '0'), // Minuutit (MM)
    ].join(':');
  return formattedDate;
}
