import { useEffect, useState } from 'react';
import { useAuth } from '../../context/authContext';
import { getSites } from '../../database/customerDB';
import CollapsibleTable from './collapsibleTable';
import { ApiProvider } from '../../context/ApiCalls';

async function getErrorCameras({ currentUser }: { currentUser: any }) {
  const errorCameras = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/error-cameras`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${await currentUser.getIdToken()}`,
      },
      body: JSON.stringify({ uid: currentUser.uid }),
    }
  );
  return (await errorCameras.json()) as string[];
}

// Apufunktio syvälliseen vertailuun
function arraysEqual(a: any, b: any) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // Vertaile jokainen elementti
  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

const Dashboard = () => {
  const { currentUser } = useAuth();
  const [userSites, setUserSites] = useState([]);
  const [handleUpdate, setHandleUpdate] = useState(0);
  const [errorCameras, setErrorCameras] = useState<string[]>([]);

  useEffect(() => {
    let isMounted = true;
    const fetchErrors = async () => {
      const newData = await getErrorCameras({ currentUser });
      if (isMounted && !arraysEqual(errorCameras, newData)) {
        setErrorCameras(newData);
      }
    };

    if (currentUser) {
      fetchErrors();
      const intervalId = setInterval(fetchErrors, 5000);
      return () => {
        isMounted = false;
        clearInterval(intervalId);
      };
    }
  }, [currentUser, errorCameras]);

  useEffect(() => {
    let isMounted = true;
    const fetchSites = async () => {
      const data = await getSites({ currentUser });
      if (isMounted) {
        setUserSites(data);
      }
    };

    if (currentUser) {
      fetchSites();
      return () => {
        isMounted = false;
      };
    }
  }, [currentUser, handleUpdate]);

  if (!currentUser && !userSites.length) {
    return <h1>Not logged in</h1>;
  }

  return (
    <ApiProvider>
      <CollapsibleTable
        userSites={userSites}
        setHandleUpdate={setHandleUpdate}
        handleUpdate={handleUpdate}
        errorCameras={errorCameras}
      />
    </ApiProvider>
  );
};

export default Dashboard;
