import { Card, Typography } from '@material-tailwind/react';
import {
  CamerasTable,
  RemoteUsersTable,
  UserInformationTable,
  ContactInformationTable,
} from './tables';
const CheckCustomerData = ({ customer, errors }: any) => {
  const showErrorIndicator = (field: any) =>
    errors && errors[field] ? <span className="text-red-500">*</span> : null;
  const totalPrice = customer.cameras.length * 15;
  return (
    <Card
      placeholder={''}
      className="mx-auto max-w-4xl p-4 md:p-8 lg:p-12 bg-primaryBlue text-white"
    >
      <Typography
        placeholder={''}
        variant="h5"
        color="white"
        className="font-semibold mb-6 text-center"
      >
        Check site information
      </Typography>

      <div className="overflow-x-auto">
        <UserInformationTable
          customer={customer}
          errors={errors}
          showErrorIndicator={showErrorIndicator}
        />
        <ContactInformationTable customer={customer} />

        <CamerasTable
          customer={customer}
          errors={errors}
          showErrorIndicator={showErrorIndicator}
        />
        <RemoteUsersTable
          customer={customer}
          errors={errors}
          showErrorIndicator={showErrorIndicator}
        />
        <div className="flex justify-end">
          {/*  TOTAL PRICE */}
          <div className="flex flex-col w-1/4">
            <div className="flex justify-between">
              <Typography placeholder={''} variant="h6">
                Total price
              </Typography>
              <Typography placeholder={''} variant="h6">
                {totalPrice} <span className="text-sm">€/month </span>
              </Typography>
            </div>
            <Typography placeholder={''}>
              *Prices are calculated based on the number of cameras and remote
              users
            </Typography>

            {/*  TOTAL PRICE */}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CheckCustomerData;
