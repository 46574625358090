import React from 'react';
import PaymentForm from './stripe';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useAuth } from '../../../context/authContext';
import { Card, CardBody } from '@material-tailwind/react';

type FormData = {
  userType: 'individual' | 'business';
  name: string;
  email: string;
  phone: string;
  address: string;
  country: string;
  vatNumber?: string;
  companyName?: string;
  uid: string | undefined;
  createdAt: Date;
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

const ContactForm: React.FC = () => {
  const { currentUser, CheckBillingInfo } = useAuth();
  const formData = {
    userType: 'individual',
    name: '',
    email: currentUser?.email,
    phone: '',
    address: '',
    country: '',
    vatNumber: '',
    companyName: '',
    uid: currentUser?.uid,
    createdAt: new Date(),
  } as FormData;

  return (
    <Card
      placeholder={''}
      className="flex justify-center items-center pb-6 w-full max-w-[600px]"
    >
      <CardBody
        placeholder={''}
        className="flex w-full items-center justify-center flex-col gap-4"
      >
        <Elements stripe={stripePromise}>
          <PaymentForm props={formData} CheckBillingInfo={CheckBillingInfo} />
        </Elements>
      </CardBody>
    </Card>
  );
};

export default ContactForm;
