import React from 'react';
import ReactDOM from 'react-dom/client';
import ToastProvider from './context/toastContext';

import App from './App.jsx';
import { AuthProvider } from './context/authContext';
import CustomerProvider from './context/customerContext';
import { ApiProvider } from './context/ApiCalls';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ApiProvider>
        <ToastProvider>
          <CustomerProvider>
            <App />
          </CustomerProvider>
        </ToastProvider>
      </ApiProvider>
    </AuthProvider>
  </React.StrictMode>
);
