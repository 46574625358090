import React from 'react';
import { Button, Typography } from '@mui/material';
const Confirmation = ({ setConfirm, setOpenModal }: any) => {
  return (
    <div className="p-4 flex flex-col max-w-2xl justify-center">
      <Typography variant="h5">
        Are you sure you want to cancel your subscription?
      </Typography>
      <br />
      <Typography>
        You will lose access to all cameras,remoteusers and recordings and data
        associated with your subscription.
      </Typography>
      <div className="flex justify-end gap-3">
        <Button
          onClick={() => {
            setOpenModal(false);
            setConfirm(false);
          }}
          variant="outlined"
          color="error"
        >
          No
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => setConfirm(true)}
        >
          Yes
        </Button>
      </div>
    </div>
  );
};

export default Confirmation;
