import React from 'react';
import './index.css';
import TopNav from './components/sidebar';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './context/authContext';
import Login from './components/auth/login';
import Register from './components/auth/register';
import Dashboard from './pages/dashboard/dashBoard';
import AddNewCustomer from './pages/cameraManagement/addNewCustomer';
import Subscriptions from './pages/subscriptions/subscriptions';
import MyAccount from './pages/cameraManagement/myAccount/myAccount';
import PrivacyPolicy from './pages/privacyPolicy/privacyPolicy';
import Contact from './pages/contact/contact';
const Home = () => <h1 className="text-black">Home</h1>;
const About = () => <PrivacyPolicy />;
/* const Contact = () => <h1>Contact</h1>; */

const History = () => <h1>History</h1>;
const Settings = () => <h1>Settings</h1>;

const Footer = () => {
  return (
    <footer className="bg-darkPurple text-white text-center py-3 text-sm sticky bottom-0 z-10">
      © 2023 ObsClouds. All rights reserved.
    </footer>
  );
};

function App() {
  const { currentUser, verifiedUser } = useAuth();
  return (
    <BrowserRouter basename="/">
      <div className="flex flex-col min-h-screen">
        <TopNav />
        <div className="flex flex-1 flex-col bg-darkPurple">
          <div className="flex-grow  overflow-auto px-[10%]">
            <div className="mx-auto p-12">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/home" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/about" element={<Navigate to="/login" />} />
                {currentUser && !verifiedUser && (
                  <Route path="/register" element={<Register />} />
                )}
                {currentUser && verifiedUser && (
                  <>
                    <Route path="/dashboard" element={<Dashboard />} />

                    <Route path="/subscriptions" element={<Subscriptions />} />
                    <Route path="/myAccount" element={<MyAccount />} />
                    <Route path="/history" element={<History />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route
                      path="/addNewCustomer"
                      element={<AddNewCustomer />}
                    />
                    <Route path="/about" element={<About />} />
                  </>
                )}
              </Routes>
            </div>
          </div>
          <Footer className="sticky bottom-0 z-10" />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
