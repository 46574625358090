import React, { useState } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const PasswordInput = ({ password }: { password: string }) => {
  // Tilamuuttuja salasanan näkyvyydelle, alkuarvo false (salasana peitettynä)
  const [showPassword, setShowPassword] = useState(false);

  // Funktio salasanan näkyvyyden vaihtamiseen
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex flex-row justify-between items-center ">
      <p className="w-24">{showPassword ? password : '********'}</p>{' '}
      <button onClick={togglePasswordVisibility}>
        {showPassword ? (
          <VisibilityOutlinedIcon />
        ) : (
          <VisibilityOffOutlinedIcon />
        )}
      </button>
    </div>
  );
};

export default PasswordInput;
