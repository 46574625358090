import React, { useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PasswordInput from './password';
import Modal from '../../../components/modal/modal';
import AddNewUser from './addNewUser';

const Remoteusers = ({
  site,
  edit,
  handleDelete,
  handleUpdate,
  setHandleUpdate,
}: any) => {
  const [openModal, setOpenModal] = useState(false);

  const closeModal = () => {
    setOpenModal(false);
  };
  const sx = { fontSize: 16, fontWeight: 'bold' };
  return (
    <>
      <Modal show={openModal} onClose={closeModal} title="Lisää etäkäyttäjä">
        <AddNewUser
          site={site}
          closeModal={closeModal}
          handleUpdate={handleUpdate}
          setHandleUpdate={setHandleUpdate}
        />
      </Modal>
      {/* Etäkäyttäjät */}
      <Box sx={{ margin: 4 }}>
        <Typography variant="h6" gutterBottom component="div">
          Etäkäyttäjät ({site.users.length})
        </Typography>
        <Table size="small" aria-label="users">
          <TableHead>
            <TableRow>
              <TableCell sx={sx}>Nimi</TableCell>
              <TableCell sx={sx} align="right">
                sähköposti
              </TableCell>
              <TableCell sx={sx} align="right">
                Käyttäjätunnus
              </TableCell>
              <TableCell sx={sx} align="right">
                Salasana
              </TableCell>
              {edit && (
                <TableCell sx={sx} align="right">
                  Muokkaa
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {site.users.map((user: any) => (
              <TableRow key={user.id}>
                <TableCell component="th" scope="row">
                  {user.name}
                </TableCell>
                <TableCell align="right">{user.email}</TableCell>
                <TableCell align="right">{user.username}</TableCell>
                <TableCell align="right">
                  <PasswordInput password={user.password} />
                </TableCell>
                {edit && (
                  <TableCell align="right">
                    <DeleteForeverIcon
                      sx={{ color: 'red', fontSize: 25 }}
                      className="cursor-pointer hover:scale-125 ease-in-out duration-300"
                      onClick={() =>
                        handleDelete({ id: user.id, type: 'user' })
                      }
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {edit && (
          <div className="mt-4 w-full flex justify-end">
            <button
              className="bg-primary text-white font-bold py-2 px-4 rounded hover:bg-primary hover:scale-110 ease-in-out duration-300"
              onClick={() => setOpenModal(true)}
            >
              Lisää Etäkäyttäjä
            </button>
          </div>
        )}
      </Box>
    </>
  );
};

export default Remoteusers;
