import React from 'react';
import { Link } from 'react-router-dom';

export const NoSites = () => {
  return (
    <div className="flex justify-center items-center flex-col gap-6">
      <h1>Et ole vielä luonut etäkohteita</h1>
      <br />
      <p>
        Voit lisätä etäkohteita painamalla alla olevaa painiketta ja täyttämällä
        lomakkeen
      </p>
      <Link
        to="/addNewCustomer"
        className="bg-primary text-white font-bold py-2 px-4 rounded hover:bg-primary hover:scale-110 ease-in-out duration-300"
      >
        Lisää sivusto
      </Link>
    </div>
  );
};
