import axios from 'axios';

/* const API_URL = process.env.REACT_APP_API_URL;
const PORT = process.env.PORT || 3001; */

export async function sendCustomer(customer: any, currentUser: any) {
  //const customerURL = "http://" + API_URL + ":" + PORT + `/newCustomer`;
  const token = await currentUser.getIdToken();

  const response = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/newCustomer',
    customer,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.CustomerID;
}
