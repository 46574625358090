import React from 'react';
const TeltonikaSetupGuide = ({ rut }: any) => {
  console.log(rut);
  return (
    <div style={{ fontFamily: 'Arial', padding: '20px' }}>
      <h1>Teltonika RUTX Reitittimen käyttöönotto</h1>
      <p>
        Tämä opas auttaa sinua ottamaan käyttöön Teltonika RUTX09 tai RUTX08
        reitittimen ja lataamaan siihen varmuuskopioitu konfiguraatiotiedosto.
      </p>

      <h2>Vaihe 1: Reitittimen kytkeminen</h2>
      <p>
        Liitä reititin virtalähteeseen ja odota, kunnes käynnistys on valmis.
        Tämä näkyy yleensä LED-valojen vakaana palamisena.
      </p>

      <h2>Vaihe 2: Laiteyhteyden muodostaminen</h2>
      <p>
        Yhdistä tietokoneesi reitittimeen joko langattomasti käyttäen
        reitittimen oletus Wi-Fi-verkkoa tai Ethernet-kaapelilla.
      </p>

      <h2>Vaihe 3: Kirjautuminen reitittimen hallintapaneeliin</h2>
      <p>
        Avaa verkkoselain ja kirjoita osoiteriville reitittimen oletus
        IP-osoite, tyypillisesti <strong>192.168.1.1</strong>. Käytä
        oletuskäyttäjänimeä ja -salasanaa (<strong>admin</strong>/
        <strong>admin</strong>) kirjautuaksesi sisään.
      </p>

      <h2>Vaihe 4: Konfiguraatiotiedoston lataaminen</h2>
      <p>
        Siirry hallintapaneelin 'System' {'->'} 'Backup' osioon. Valitse 'Upload
        file' ja etsi tietokoneeltasi varmuuskopioitu konfiguraatiotiedosto.
        Valitse tiedosto ja paina 'Upload'.
      </p>

      <h2>Vaihe 5: Reitittimen uudelleenkäynnistys</h2>
      <p>
        Kun konfiguraatiotiedosto on ladattu, reititin saattaa pyytää
        uudelleenkäynnistystä. Hyväksy tämä tai käynnistä reititin manuaalisesti
        uudelleen, jotta muutokset tulevat voimaan.
      </p>

      <h2>Vinkkejä ja ohjeita</h2>
      <ul>
        <li>
          Tarkista Teltonikan dokumentaatio tai tukisivut, jos kohtaat ongelmia.
        </li>
        <li>Pidä reitittimen firmware ajan tasalla turvallisuussyistä.</li>
        <li>Käytä vahvoja salasanoja ja vaihda oletussalasanat.</li>
      </ul>
    </div>
  );
};

export default TeltonikaSetupGuide;
