import React, { useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Modal from '../../../components/modal/modal';
import AddNewCamera from './addNewCamera';
import CameraConfiguration from '../../../components/guides/cameraConfigurration';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
const Cameras = ({
  edit,
  setEdit,
  site,
  convertTimestamp,
  handleDelete,
  handleUpdate,
  setHandleUpdate,
  errorCameras,
}: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [configurationOpen, setConfigurationOpen] = useState(false);
  const [camera, setCamera] = useState({});
  const closeModal = () => {
    setOpenModal(false);
  };
  console.log('rendering cameras');
  const checkIfCameraIsError = (camera: any) => {
    return errorCameras.includes(camera.id);
  };
  const sx = { fontSize: 16, fontWeight: 'bold' };

  return (
    <>
      <Modal show={openModal} onClose={closeModal} title="Lisää kamera">
        <AddNewCamera
          site={site}
          closeModal={closeModal}
          handleUpdate={handleUpdate}
          setHandleUpdate={setHandleUpdate}
        />
      </Modal>
      <Modal
        show={configurationOpen}
        onClose={() => setConfigurationOpen(false)}
      >
        <CameraConfiguration camera={camera} />
      </Modal>
      <Box sx={{ margin: 4 }}>
        <div className="flex justify-between">
          <Typography variant="h6" gutterBottom component="div">
            Kamerat ({site.cameras?.length || 0})
          </Typography>
          <EditNoteIcon
            fontSize="large"
            color={edit ? 'action' : 'success'}
            onClick={() => setEdit(!edit)}
            className="cursor-pointer hover:scale-110 ease-in-out duration-300"
          />
        </div>
        <Table size="small" aria-label="cameras">
          <TableHead>
            <TableRow sx={{ fontSize: 16 }}>
              <TableCell sx={sx}></TableCell>
              <TableCell sx={sx}>Nimi</TableCell>
              <TableCell sx={sx} align="right">
                Kameran ID
              </TableCell>
              <TableCell sx={sx} align="right">
                IP-osoite
              </TableCell>
              <TableCell sx={sx} align="right">
                Sijainti
              </TableCell>
              <TableCell sx={sx} align="right">
                Lisätty
              </TableCell>
              {edit && (
                <TableCell sx={sx} align="right">
                  Muokkaa
                </TableCell>
              )}
              <TableCell sx={{ fontSize: 16 }} align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {site.cameras &&
              site.cameras.map((camera: any) => (
                <TableRow key={camera.id} sx={{}}>
                  <TableCell>
                    {checkIfCameraIsError(camera) ? (
                      <VideocamOffIcon sx={{ color: 'red' }} />
                    ) : (
                      <VideocamIcon sx={{ color: 'green' }} />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: checkIfCameraIsError(camera) ? 'red' : 'black',
                    }}
                    component="th"
                    scope="row"
                  >
                    {camera.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: checkIfCameraIsError(camera) ? 'red' : 'black',
                    }}
                    align="right"
                  >
                    {camera.id}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: checkIfCameraIsError(camera) ? 'red' : 'black',
                    }}
                    align="right"
                  >
                    {camera.ip}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: checkIfCameraIsError(camera) ? 'red' : 'black',
                    }}
                    align="right"
                  >
                    {camera.location}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: checkIfCameraIsError(camera) ? 'red' : 'black',
                    }}
                    align="right"
                  >
                    {convertTimestamp(camera.createdAT)}
                  </TableCell>
                  {edit && (
                    <TableCell align="right">
                      <DeleteForeverIcon
                        sx={{
                          fontSize: 25,
                          color: 'red',
                        }}
                        className="cursor-pointer hover:scale-125 ease-in-out duration-300"
                        onClick={() =>
                          handleDelete({ id: camera.id, type: 'camera' })
                        }
                      />
                    </TableCell>
                  )}
                  <TableCell align="right">
                    <HelpCenterIcon
                      sx={{
                        fontSize: 25,
                        color: '#43687e',
                      }}
                      className="cursor-pointer hover:scale-125 ease-in-out duration-300"
                      onClick={() => {
                        setCamera({
                          camera: {
                            password: camera.password,
                            gateway: site.rutAddress,
                            ip: camera.ip,
                          },
                        });
                        setConfigurationOpen(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {edit && (
          <div className="mt-4 w-full flex justify-end">
            <button
              className="bg-primary text-white font-bold py-2 px-4 rounded hover:bg-primary hover:scale-110 ease-in-out duration-300"
              onClick={() => setOpenModal(true)}
            >
              Lisää kamera
            </button>
          </div>
        )}
      </Box>
    </>
  );
};

export default Cameras;
