import React, { useState } from 'react';
import { useAuth } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import ResetPassword from './resetPassword';
import {
  Button,
  Input,
  Card,
  CardBody,
  Typography,
} from '@material-tailwind/react';

const Login = () => {
  const { loginWithEmailAndPassword, verifiedUser } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await loginWithEmailAndPassword(email, password);
      if (response.user.emailVerified && verifiedUser) {
        navigate('/dashboard');
      } else {
        if (response.user.email) {
          navigate('/register');
        }
      }
    } catch (error) {
      setError('Failed to log in!');
      console.error(error.message);
    }
  };

  return (
    <div className="flex items-center justify-center ">
      <Card
        placeholder={''}
        className="w-full max-w-md space-y-8 p-10 bg-white shadow-xl rounded-lg"
      >
        <CardBody>
          <div className="text-center mb-6">
            <Typography placeholder={''} variant="h5" color="gray">
              Log In to Your Account
            </Typography>
          </div>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <Input
              type="email"
              color="purple"
              size="lg"
              label="Email address"
              onChange={(e) => setEmail(e.target.value)}
              crossOrigin={true}
            />
            <Input
              type="password"
              color="purple"
              size="lg"
              label="Password"
              onChange={(e) => setPassword(e.target.value)}
              crossOrigin={true}
            />
            {/* Dont remember password */}
            <div className="flex items-center justify-center">
              <div className="text-sm">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setResetDialogOpen(true);
                  }}
                  className="font-medium text-purple-500 hover:text-purple-400"
                >
                  Forgot your password?
                </button>
              </div>
            </div>
            <ResetPassword
              resetDialogOpen={resetDialogOpen}
              setResetDialogOpen={setResetDialogOpen}
            />
            {error && (
              <p className="text-red-500 text-sm text-center">{error}</p>
            )}
            <div className="flex justify-center">
              <Button
                placeholder={''}
                type="submit"
                color="purple"
                size="lg"
                ripple
              >
                Log In
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default Login;
