import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-5xl gap-3 flex flex-col">
      <h1 className="text-2xl">Yksityisyydensuojakäytäntö</h1>
      <span className="text-gray-700 p-3">
        Yksityisyydensuojakäytäntö Tervetuloa [Verkkosivuston Nimi]
        yksityisyydensuojakäytäntöön. Sitoudumme suojelemaan ja kunnioittamaan
        yksityisyyttäsi. Tämä käytäntö selittää, miten keräämme, käytämme,
        säilytämme ja suojaamme henkilötietojasi kun käytät [Verkkosivuston
        Nimi] palveluitamme.
      </span>
      <h2 className="text-xl">Henkilötietojen kerääminen</h2>
      <span className="text-gray-700 p-3">
        Kun rekisteröidyt kuukausitilaukseemme, saatamme kerätä seuraavia
        tietoja:
        <li>
          Henkilökohtaiset tiedot: nimi, osoite, sähköpostiosoite ja
          puhelinnumero.
        </li>
        <li>
          Maksutiedot: pankkikortin numero, voimassaoloaika, CVV-koodi ja
          laskutusosoite.
        </li>
      </span>
      <h2 className="text-xl">Henkilötietojen käyttö</h2>
      <span className="text-gray-700 p-3">
        Käytämme keräämiämme henkilötietoja seuraaviin tarkoituksiin:
        <li>
          Kuukausitilauksen hallinta: käytämme henkilötietojasi tilauksesi
          käsittelyyn, maksujen suorittamiseen ja laskutukseen.
        </li>
        <li>
          Asiakaspalvelu: käytämme henkilötietojasi vastataksemme kysymyksiisi
          ja ratkaistaksemme ongelmasi.
        </li>
        <li>
          Markkinointi: käytämme henkilötietojasi lähettääksemme sinulle
          markkinointiviestejä, jotka saattavat kiinnostaa sinua.
        </li>
      </span>
      <h2 className="text-xl">Henkilötietojen jakaminen</h2>
      <span className="text-gray-700 p-3">
        Emme jaa pankkikorttitietojasi kolmansille osapuolille, lukuun ottamatta
        maksujen käsittelyyn tarvittavia maksupalveluntarjoajia. Kaikki
        kolmannet osapuolet, joiden kanssa jaamme henkilötietojasi, ovat
        velvollisia suojelemaan tietojasi ja käyttämään niitä vain sopimuksen
        mukaisesti.
      </span>
      <h2 className="text-xl">Henkilötietojen suojaaminen</h2>
      <span className="text-gray-700 p-3">
        Käytämme alan standardeja vastaavia turvatoimia, mukaan lukien
        SSL-salaus, suojataksemme henkilötietojasi ja erityisesti
        pankkikorttitietojasi. Vaikka pyrimme käyttämään kaupallisesti
        hyväksyttäviä keinoja suojataksemme tietojasi, mikään siirto Internetin
        kautta tai elektroninen säilytys ei ole 100% turvallista.
      </span>
      <h2 className="text-xl">Käyttäjien Oikeudet</h2>
      <span className="text-gray-700 p-3">
        Sinulla on oikeus pyytää kopio henkilötiedoistasi, jotka meillä on
        hallussamme, oikaista virheelliset tiedot, rajoittaa tietojen käsittelyä
        tai poistaa henkilötietosi kokonaan. Voit myös vastustaa
        henkilötietojesi käsittelyä tai pyytää tietojen siirtoa toiselle
        organisaatiolle. Nämä oikeudet ovat voimassa sovellettavien lakien
        mukaisesti.
      </span>
      <h2 className="text-xl">Evästeet</h2>
      <span className="text-gray-700 p-3">
        Käytämme evästeitä parantaaksemme käyttökokemustasi. Evästeet ovat
        pieniä tekstitiedostoja, jotka tallennetaan tietokoneellesi, kun käytät
        verkkosivustoamme. Käytämme evästeitä tunnistaaksemme sinut kun palaat
        verkkosivustollemme ja personoidaksemme sisältöä ja mainoksia sinulle.
        Voit estää evästeiden tallentamisen muuttamalla selaimesi asetuksia.
      </span>
      <h2 className="text-xl">Muutokset Yksityisyydensuojakäytäntöön</h2>
      <span className="text-gray-700 p-3">
        Pidätämme oikeuden päivittää tätä yksityisyydensuojakäytäntöä
        tarvittaessa. Suosittelemme, että tarkistat tämän sivun säännöllisesti
        mahdollisten muutosten varalta. Tämä yksityisyydensuojakäytäntö on
        viimeksi päivitetty [Päivämäärä].
      </span>
      <h2 className="text-xl">Yhteydenotto</h2>
      <span className="text-gray-700 p-3">
        Jos sinulla on kysymyksiä tästä yksityisyydensuojakäytännöstä, ota
        yhteyttä meihin sähköpostitse osoitteeseen [Sähköposti].
      </span>
    </div>
  );
};

export default PrivacyPolicy;
