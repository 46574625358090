import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useApi } from '../../../context/ApiCalls';

const AddNewCamera = ({
  site,
  closeModal,
  handleUpdate,
  setHandleUpdate,
}: any) => {
  const [cameraName, setCameraName] = useState<string | null>(null);
  const [cameraLocation, setCameraLocation] = useState('');
  const { addCamera } = useApi();
  const handleNewCamera = async () => {
    if (!cameraName) {
      console.log('Täytä kaikki kentät');
      return;
    }
    console.log(cameraName, cameraLocation, site.siteID);
    const camera = { name: cameraName, location: cameraLocation };
    const response = await addCamera({
      camera: camera,
      siteID: site.siteID,
    });
    if (response.status === 200) {
      setHandleUpdate(handleUpdate + 1);
    }
    if (response.status === 400) {
      const errorData = await response.json();
      console.error(errorData.error);
    }

    closeModal();
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Lisää kamera
      </Typography>
      <div className="flex justify-row gap-4 p-4">
        <Typography variant="h6" gutterBottom component="div">
          Kameran nimi
        </Typography>
        <input
          type="text"
          className="border-2 border-gray-300 p-2 rounded"
          onChange={(e) => setCameraName(e.target.value)}
        />

        <Typography variant="h6" gutterBottom component="div">
          Kameran Sijainti
        </Typography>
        <input
          type="text"
          className="border-2 border-gray-300 p-2 rounded"
          onChange={(e) => setCameraLocation(e.target.value)}
        />
      </div>
      <div className="flex justify-end">
        <button
          className="bg-primary text-white font-bold py-2 px-4 rounded hover:bg-primary hover:scale-110 ease-in-out duration-300"
          onClick={handleNewCamera}
        >
          Lisää kamera
        </button>
      </div>
    </div>
  );
};

export default AddNewCamera;
