import React from 'react';

const Modal = ({
  show,
  onClose,
  children,
  title,
}: {
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
}) => {
  if (!show) {
    return null;
  }

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center"
      onClick={onClose}
    >
      <div
        className="p-5 border w-auto shadow-lg rounded-md bg-white transform transition-all"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 transition ease-in-out duration-600"
          >
            <span className="sr-only">Sulje</span>
            &#10005; {/* Tämä on yksinkertainen risti-ikoni */}
          </button>
        </div>

        <div className="mt-2">
          <h3 className="text-lg font-semibold p-4">{title}</h3>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
