import React from 'react';
import { Typography } from '@mui/material';

const PaymentCard = ({ paymentMethod }: any) => {
  return (
    <div className="relative">
      <Typography
        variant="h5"
        component="div"
        fontWeight="bold"
        marginBottom={2}
      >
        Payment Method
      </Typography>
      <div className="p-3 flex flex-col gap-3">
        <Typography component="div" variant="body1" color="textSecondary">
          <strong> Card Brand:</strong> {paymentMethod.card.brand.toUpperCase()}
        </Typography>
        <Typography component="div" variant="body1" color="textSecondary">
          <strong> Card Number: </strong>**** **** ****{' '}
          {paymentMethod.card.last4}
        </Typography>
        <Typography component="div" variant="body1" color="textSecondary">
          <strong> Expires: </strong> {paymentMethod.card.exp_month}/
          {paymentMethod.card.exp_year}
        </Typography>
      </div>
    </div>
  );
};

export default PaymentCard;
