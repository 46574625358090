import React from 'react';
import { Input, TextField, Button, Typography } from '@mui/material';
import { useAuth } from '../../context/authContext';
import { useToast } from '../../context/toastContext';
import CircularProgress from '@mui/material/CircularProgress';

async function sendFeedback(feedback: any) {
  const apikey = process.env.REACT_APP_APIBACKENDKEY as string;
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/feedback`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apikey,
      },
      body: JSON.stringify(feedback),
    }
  );
  console.log('response', response);
  return response;
}

const Contact = () => {
  const { currentUser } = useAuth();
  const { showToast } = useToast();
  const [Loading, setLoading] = React.useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const feedback = {
      name: e.target.name.value,
      email: e.target.email.value,
      feedback: e.target.feedback.value,
      user: currentUser.uid,
    };
    const response = await sendFeedback(feedback);

    if (response.status === 200) {
      showToast('Thank you for your feedback');
      // reset form
      e.target.reset();
      setLoading(false);
    }
  };
  return (
    <div className="flex justify-center max-w-4xl min-w-full ">
      <form
        className="flex flex-col  gap-8 p-8 max-w-lg w-full justify-center bg-blue-gray-100 rounded-xl"
        onSubmit={(e) => handleSubmit(e)}
      >
        <Typography
          variant="h5"
          component="div"
          fontWeight="bold"
          marginBottom={2}
        >
          Contact Us
        </Typography>
        <Input id="name" type="text" required placeholder="Name" />
        <Input id="email" type="email" required placeholder="Email" />
        <TextField
          id="feedback"
          variant="outlined"
          multiline
          placeholder="Message"
        />
        <Button type="submit" variant="contained" color="success">
          {Loading ? <CircularProgress size={25} color="warning" /> : 'Submit'}
        </Button>
      </form>
    </div>
  );
};

export default Contact;
