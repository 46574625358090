import React, { useState, useEffect } from 'react';
import { Input, Typography, Button } from '@material-tailwind/react';

const RemoteUser = ({ customer, setRemoteUsers }: any) => {
  const [localremoteusers, setlocalremoteusers] = useState(
    customer.remoteUsers
  );
  useEffect(() => {
    setRemoteUsers(localremoteusers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localremoteusers]);

  const handleAddRemoteUser = () => {
    setlocalremoteusers([
      ...localremoteusers,
      { name: '', email: '', phone: '' },
    ]);
  };
  const handleRemoveRemoteUser = (index: number) => {
    setlocalremoteusers(
      localremoteusers.filter((_: any, i: any) => i !== index)
    );
  };
  const handleNameChange = (index: number, value: any) => {
    const newlocalremoteusers = [...localremoteusers];
    newlocalremoteusers[index].name = value;
    setlocalremoteusers(newlocalremoteusers);
  };
  const handleEmailChange = (index: number, value: any) => {
    const newlocalremoteusers = [...localremoteusers];
    newlocalremoteusers[index].email = value;
    setlocalremoteusers(newlocalremoteusers);
  };
  const handlePhoneChange = (index: number, value: any) => {
    const newlocalremoteusers = [...localremoteusers];
    newlocalremoteusers[index].phone = value;
    setlocalremoteusers(newlocalremoteusers);
  };

  return (
    <div className="bg-primaryBlue rounded-md p-6 mb-4 flex-col">
      <Typography placeholder={''} variant="h4" color="white" className="mb-4">
        Remote users
      </Typography>
      {localremoteusers.map((user: any, index: number) => (
        <div
          key={index}
          className="flex flex-col w-full mb-4 border p-4 pr-8 rounded-lg relative"
        >
          <div className="absolute -top-4 -right-2">
            <Button
              placeholder={''}
              type="button"
              onClick={() => handleRemoveRemoteUser(index)}
              className="bg-red-500 text-white p-2 rounded-full  w-8 hover:bg-red-800"
            >
              X
            </Button>
          </div>
          <div className="mb-4">
            <Input
              type="text"
              crossOrigin={true}
              value={user.name}
              onChange={(e) => handleNameChange(index, e.target.value)}
              label="Name"
              color="white"
            />
          </div>
          <div className="mb-4">
            <Input
              type="email"
              crossOrigin={true}
              value={user.email}
              onChange={(e) => handleEmailChange(index, e.target.value)}
              label="Email"
              color="white"
            />
          </div>
          <div className="mb-4">
            <Input
              type="text"
              crossOrigin={true}
              value={user.phone}
              onChange={(e) => handlePhoneChange(index, e.target.value)}
              label="Phone number"
              color="white"
            />
          </div>
        </div>
      ))}
      <div className="flex mt-auto justify-end">
        <Button
          placeholder={''}
          type="button"
          onClick={handleAddRemoteUser}
          className="bg-green-500 text-white p-2 rounded-md"
        >
          Add new remote user
        </Button>
      </div>
    </div>
  );
};

export default RemoteUser;
