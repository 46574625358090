import React, { useEffect } from 'react';
import { useAuth } from '../../../context/authContext';
import { useToast } from '../../../context/toastContext';
import { sendEmailVerification } from 'firebase/auth';
import LoadingGear from '../../loadingGear';
import { Button, Card, Typography } from '@material-tailwind/react';
const VerifyEmail = () => {
  const { currentUser, verifiedEmail, checkEmailVerification } = useAuth();
  const { showToast } = useToast();

  if (verifiedEmail) {
    // Jos sähköposti on jo vahvistettu, voit ohjata käyttäjän toiselle sivulle tai näyttää viestin
    showToast('Sähköposti on jo vahvistettu.');
    // Oletetaan, että tässä kohtaa ohjaus tapahtuu ohjelmallisesti.
  }
  useEffect(() => {
    if (!verifiedEmail) {
      const interval = setInterval(() => {
        checkEmailVerification();
      }, 2000); // Tarkistetaan sähköpostin vahvistus 5 sekunnin välein

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, verifiedEmail]);

  function sendEmailVerificationAgain(user: any) {
    // [START sendemailverification]
    sendEmailVerification(user).then(() => {
      showToast('Email verification link has been sended again! ', {
        type: 'success',
      });
      // Email verification sent!
      // ...
    });
    // [END sendemailverification]
  }

  // Näytä latausindikaattori ja kehota käyttäjää vahvistamaan sähköpostinsa
  return (
    <div className="flex flex-col items-center justify-center ">
      <Card
        placeholder={''}
        className="flex justify-center items-center pb-6 w-full max-w-[600px] "
      >
        <div className="text-center">
          <div className="flex justify-center p-6">
            <LoadingGear customState={'Please verify your email!'} />
          </div>
          <div className="flex flex-col justify-center p-4">
            <Typography
              placeholder={''}
              variant="h1"
              className="text-lg font-semibold  mb-4"
            >
              Please verify your email address
            </Typography>
            <p>
              We have sent email verification request on your email:{' '}
              {currentUser.email}
            </p>
            <p>Check your email and click on the link to verify your email.</p>
          </div>
        </div>
        <div className="mt-4">
          <Button
            placeholder={''}
            className="px-4 py-2 text-white bg-primary rounded-lg"
            onClick={() => sendEmailVerificationAgain(currentUser)}
          >
            Send verification email again
          </Button>
        </div>

        <div className="mt-4"></div>
      </Card>
    </div>
  );
};

export default VerifyEmail;
