import React, { useState, FormEvent } from 'react';
import {
  useStripe,
  useElements,
  CardElement,
  AddressElement,
} from '@stripe/react-stripe-js';
import { useAuth } from '../../../context/authContext';
import axios from 'axios';

type FormData = {
  userType: 'individual' | 'business';
  name: string;
  email: string;
  phone: string;
  address: string;
  country: string;
  vatNumber?: string;
  companyName?: string;
  uid: string | undefined;
  createdAt: Date;
};
interface PaymentFormProps {
  props: FormData;
  isFormValidated?: boolean;
  CheckBillingInfo: () => void;
}

const PaymentForm: React.FC<PaymentFormProps> = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentStatus, setPaymentStatus] = useState<string | null>(null);
  const { currentUser, CheckBillingInfo } = useAuth();

  async function sendDataToBackend(
    paymentMethodId: string,
    paymentMethod: any,
    billingDetail: any
  ) {
    const data = { ...props, paymentMethodId, paymentMethod, billingDetail };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/save-stripe-customer`,
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${await currentUser?.getIdToken()}`,
          },
        }
      );
      if (response.status === 200) {
        // Handle success
        await CheckBillingInfo();
      }
    } catch (error) {
      console.error('Error sending data to backend:', error);
      // Handle error
    }
  }

  const handleSubmitCard = async (event: FormEvent) => {
    event.preventDefault();
    if (!elements || !stripe) return;

    const cardElement = elements.getElement(CardElement);
    const addressElement = elements.getElement(AddressElement);
    if (!cardElement || !addressElement) return;

    const billingDetails = await addressElement.getValue();
    const billingDetail = {
      ...billingDetails.value,
      email: currentUser?.email,
      uid: currentUser?.uid,
      address: {
        ...billingDetails.value.address,
        line2: billingDetails.value.address.line2 || undefined,
      },
    };

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setPaymentStatus(`Error adding card: ${error.message}`);
    } else {
      setPaymentStatus('Payment method successfully created!');
      sendDataToBackend(paymentMethod?.id || '', paymentMethod, billingDetail);
    }
  };

  return (
    <div className="p-4 max-w-md mx-auto bg-white shadow-md rounded-lg w-full">
      <form onSubmit={handleSubmitCard} className="space-y-6">
        <div className="p-4 bg-gray-100 rounded-md">
          <AddressElement
            options={{
              mode: 'billing',
              fields: {
                phone: 'always',
              },
              allowedCountries: ['FI'],
            }}
          />
          <div className="m-4">
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: '18px',

                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />
          </div>
        </div>
        <button
          className="w-full bg-primary text-white font-bold py-2 px-4 rounded hover:bg-primary disabled:opacity-50"
          type="submit"
          disabled={!stripe}
        >
          Finish creating account!
        </button>
      </form>
      {paymentStatus && (
        <div className="mt-4 p-2 text-center text-sm text-gray-600">
          {paymentStatus}
        </div>
      )}
    </div>
  );
};

export default PaymentForm;
