import React, { createContext, ReactNode, useContext } from 'react';
import { useAuth } from './authContext';
export const ApiContext = createContext<any>(null);

export const ApiProvider = ({ children }: { children: ReactNode }) => {
  const { currentUser } = useAuth();

  const baseURL = process.env.REACT_APP_BACKEND_URL;

  const deleteCamera = async ({
    camera,
    sub,
  }: {
    camera: string;
    sub: number;
  }): Promise<any> => {
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/delete-camera/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          camera: camera,
          subID: sub,
        }),
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const deleteRemoteUser = async (remoteUser: number) => {
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/delete-remote-user/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          remoteUser: remoteUser,
        }),
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const addCamera = async ({
    camera,
    siteID,
  }: {
    camera: { cameraName: string; cameraLocation: string };
    siteID: number;
  }) => {
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/add-camera/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ camera, siteID }),
      });

      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const addRemoteUser = async ({
    remoteUser,
    siteID,
  }: {
    remoteUser: { name: string; email: string; phone: string };
    siteID: number;
  }) => {
    const token = await currentUser.getIdToken();

    try {
      const response = await fetch(baseURL + '/add-remote-user/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ remoteUser, siteID }),
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const value = {
    deleteCamera,
    deleteRemoteUser,
    addCamera,
    addRemoteUser,
  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  return useContext(ApiContext);
};
