import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useAuth } from '../../../../context/authContext';
import { updatePaymentCard } from '../../../../context/apiFunctions';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

const CardForm = ({ paymentMethod, updateCard }: any) => {
  return (
    <div>
      <Elements stripe={stripePromise}>
        <UpdatePaymentMethod
          currentPaymentMethod={paymentMethod}
          updateCard={updateCard}
        />
      </Elements>
    </div>
  );
};

const UpdatePaymentMethod = ({ currentPaymentMethod, updateCard }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = React.useState<any>(null);
  const { currentUser } = useAuth();
  const handleUpdate = async (event: any) => {
    event.preventDefault();
    if (!elements || !stripe) return;

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) return;
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
      setError(error);
    } else {
      const response = await updatePaymentCard({
        paymentMethodId: paymentMethod?.id,
        customerID: currentPaymentMethod.customer,
        currentUser: currentUser,
      });
      console.log(response);
      if (response.error) {
        setError(response.error);
      }
      if (response.message === 'Card updated') {
        setError(null);
        updateCard();
      }
    }
  };
  return (
    <div className="relative w-full min-w-[600px] p-6">
      <form onSubmit={handleUpdate}>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '18px',

                color: '#424770',
                backgroundColor: 'white',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
        {error && <p className="text-red-400 p-2">{error.message}</p>}
        <div className="flex m-4 w-full justify-end pr-3 mr-3">
          <button
            className="p-3 bg-primaryBlue text-white rounded-xl  ease-in-out duration-300 hover:scale-110"
            type="submit"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default CardForm;
