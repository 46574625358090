// AUTH CONTEXT PROVIDER

import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from 'react';
import {
  onAuthStateChanged,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  reload,
  setPersistence,
  browserLocalPersistence,
  inMemoryPersistence,
} from 'firebase/auth';

import { User } from 'firebase/auth';
import { auth } from '../firebase';
import { useToast } from './toastContext';
import { FirebaseError } from 'firebase/app';
import { getUserData } from './apiFunctions';

type AuthResponse = {
  user?: User | null; // Replace 'User' with the correct Firebase User type or your custom user type
  error?: string;
};
const dummyAuthResponse: AuthResponse = { user: undefined, error: undefined };

interface AuthContextProps {
  currentUser: any; // Tämä voi olla mukautettu tyyppi tai Firebase User -tyyppi
  verifiedUser: boolean;
  verifiedEmail: boolean;
  userBilling: boolean;
  setUserBilling: React.Dispatch<React.SetStateAction<boolean>>;
  checkEmailVerification: () => Promise<void>;
  logout: () => Promise<void>;
  registerWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<void>;
  loginWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<AuthResponse>;
  CheckBillingInfo: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextProps>({
  currentUser: null,
  verifiedUser: false,
  userBilling: false,
  setUserBilling: () => {},
  checkEmailVerification: async () => {},
  logout: async () => {},
  registerWithEmailAndPassword: async (email: string, password: string) => {},
  loginWithEmailAndPassword: async (email: string, password: string) =>
    dummyAuthResponse,
  verifiedEmail: false,
  CheckBillingInfo: async () => {},
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<any | null>(null); // Tämä voi olla mukautettu tyyppi tai Firebase User -tyyppi
  const [pending, setPending] = useState(true);
  const [verifiedEmail, setVerifiedEmail] = useState(false);
  const [userBilling, setUserBilling] = useState(false);
  const [verifiedUser, setVerifiedUser] = useState(false);

  const { showToast } = useToast();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user?.emailVerified) {
        setVerifiedEmail(true);
      }
      setCurrentUser(user);
      setPending(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (currentUser && verifiedEmail && userBilling) {
      setVerifiedUser(true);
    }
  }, [currentUser, verifiedEmail, userBilling]);

  useEffect(() => {
    if (currentUser && verifiedEmail) {
      CheckBillingInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, verifiedEmail]);

  const checkEmailVerification = async () => {
    if (currentUser && !verifiedEmail) {
      await reload(currentUser);
      if (currentUser.emailVerified) {
        setVerifiedEmail(true);
        // Voit myös ilmoittaa käyttäjälle, että sähköposti on nyt vahvistettu
        showToast(
          'Sähköposti on nyt vahvistettu. Jatka seuraavaan vaiheeseen.',
          {
            type: 'success',
          }
        );
      }
    }
  };

  const CheckBillingInfo = async () => {
    try {
      // Ensure currentUser is defined
      if (!currentUser) {
        console.error('currentUser is undefined');
        return;
      }

      // Get billing info from firebase users collection
      const data = await getUserData({ currentUser: currentUser });
      console.log('data:', currentUser);
      // Provide a default object to safely destructure 'user'
      const { user } = data || {};
      console.log('user:', user);
      if (!user) {
        console.log('No such document!');
      } else {
        if (user.billingID) {
          console.log('Billing info found');
          setUserBilling(true);
        } else {
          console.log('Billing info not complete');
          setUserBilling(false);
        }
      }
    } catch (error) {
      console.error('Error checking billing info:', error);
    }
  };

  const loginWithEmailAndPassword = async (email: string, password: string) => {
    try {
      await setPersistence(auth, inMemoryPersistence);
      const response = await signInWithEmailAndPassword(auth, email, password);

      if (response.user) {
        return { user: response.user };
      }
    } catch (error) {
      console.error('Error signing in with email and password: ', error);
      let errorMessage = 'Kirjautuminen epäonnistui. Yritä uudelleen.';
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/user-not-found':
            errorMessage = 'User not found.';
            break;
          case 'auth/wrong-password':
            errorMessage = 'Wrong password.';
            break;
          case 'auth/invalid-login-credentials':
            errorMessage = 'Invalid email or password.';
            break;

          default:
            errorMessage = error.message;
            break;
        }
      }

      showToast(errorMessage, {
        type: 'error',
        autoClose: 5000,
        position: 'top-center',
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          padding: '10px',
        },
      });
    }
    return { user: null, error: 'Unexpected error occurred' };
  };

  const registerWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    try {
      const userCredentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      sendEmailVerification(userCredentials.user)
        .then(() => {
          // Email verification sent!
          // ...
        })
        .catch((error) => {
          console.log('Error sending email verification', error);
        });
    } catch (error) {
      console.error('Error signing up with email and password: ', error);
      let errorMessage = 'Tuntematon virhe tapahtui. Yritä uudelleen.';

      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/email-already-in-use':
            errorMessage = 'Sähköpostiosoite on jo käytössä.';
            break;
          case 'auth/invalid-email':
            errorMessage = 'Sähköpostiosoite on virheellinen.';
            break;
          case 'auth/weak-password':
            errorMessage = 'Salasana on liian heikko.';
            break;
          // Lisää tarvittaessa muita virhekoodien tapauksia
          default:
            errorMessage = error.message; // Vaihtoehtoisesti voit näyttää Firebasen oman viestin
            break;
        }
      }

      showToast(errorMessage, {
        type: 'error',
        autoClose: 5000,
        position: 'top-center',
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          padding: '10px',
        },
      });
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out: ', error);
      let errorMessage = 'Uloskirjautuminen epäonnistui. Yritä uudelleen.';
      if (error instanceof FirebaseError) {
        // Voit lisätä tarkempaa virheiden käsittelyä tässä, jos tarpeen
        errorMessage = error.message;
      }

      showToast(errorMessage, {
        type: 'error',
        autoClose: 5000,
        position: 'top-center',
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          padding: '10px',
        },
      });
    }
  };

  if (pending) {
    return <>Loading...</>;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        verifiedUser,
        logout,
        registerWithEmailAndPassword,
        loginWithEmailAndPassword,
        verifiedEmail,
        userBilling,
        checkEmailVerification,
        setUserBilling,
        CheckBillingInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
