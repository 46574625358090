interface BasicInfoType {
  name: string;
  street: string;
  city: string;
  postalCode: string;
}

interface ContactPersonType {
  name: string;
  email: string;
  phone: string;
}

interface Camera {
  name: string;
  location: string;
}

interface RemoteUserType {
  name: string;
  email: string;
  phone: string;
}

interface Customer {
  createdAt: Date;
  owner: string;
  basicInfo: BasicInfoType;
  contactPerson: ContactPersonType;
  cameras: Camera[];
  remoteUsers: RemoteUserType[];
}
export function handleNextValidation({
  activeStep,
  customer,
  setErrorMessages,
  showToast,
}: {
  activeStep: number;
  customer: Customer;
  setErrorMessages: any;
  showToast: any;
}) {
  if (activeStep === 0) {
    const { basicInfo } = customer;
    if (!basicInfo.name) {
      showToast('Name is required', { type: 'error' });
      setErrorMessages((prev: any) => ({
        ...prev,
        basicInfo: 'Name is required!',
      }));
      return false;
    } else {
      setErrorMessages((prev: any) => ({
        ...prev,
        basicInfo: '',
      }));
      return true;
    }

    //TODO jos katuosoite puuttuu niin kehoita täyttämään mutta ei pakollinen?

    // validate basicInfo
  }

  if (activeStep === 1) {
    const { contactPerson } = customer;

    // Resetoi olemassa olevat virheviestit contactPerson-kentille
    setErrorMessages((prev: any) => ({
      ...prev,
      contactPersonName: '',
      contactPersonEmail: '',
      contactPersonPhone: '',
    }));

    let hasError = false;

    // Tarkista, onko nimi annettu
    if (!contactPerson.name) {
      showToast('Nimi on pakollinen', { type: 'error' });
      setErrorMessages((prev: any) => ({
        ...prev,
        contactPersonName: 'Nimi on pakollinen!',
      }));
      hasError = true;
    }

    // Tarkista, onko joko sähköposti tai puhelinnumero annettu
    if (!contactPerson.email && !contactPerson.phone) {
      showToast('Sähköposti tai puhelinnumero on pakollinen', {
        type: 'error',
      });
      setErrorMessages((prev: any) => ({
        ...prev,
        contactPersonEmail: 'Sähköposti tai puhelinnumero on pakollinen!',
        contactPersonPhone: 'Sähköposti tai puhelinnumero on pakollinen!',
      }));
      hasError = true;
    }

    if (hasError) return false;
    return true;
  }

  if (activeStep === 2) {
    const { cameras } = customer;
    setErrorMessages((prev: any) => ({
      ...prev,
      cameras: '',
    }));

    let hasError = false;

    // Tarkista, onko cameras-taulukko tyhjä
    if (!cameras || cameras.length === 0) {
      showToast('Vähintään yksi kamera on pakollinen', { type: 'error' });
      setErrorMessages((prev: any) => ({
        ...prev,
        cameras: 'Vähintään yksi kamera on pakollinen!',
      }));
      hasError = true;
    } else {
      // Käy läpi kaikki kamerat ja tarkista niiden nimet
      cameras.forEach((camera, index) => {
        if (!camera.name) {
          showToast(`Kameran ${index + 1} nimi on pakollinen`, {
            type: 'error',
          });
          setErrorMessages((prev: any) => ({
            ...prev,
            [`cameraName${index}`]: 'Kameran nimi on pakollinen!',
          }));
          hasError = true;
        }
      });
    }

    if (hasError) return false;
    return true;
  }

  if (activeStep === 3) {
    const { remoteUsers } = customer;

    setErrorMessages((prev: any) => ({
      ...prev,
      remoteUsers: '',
    }));
    let hasError = false;

    // Tarkista, onko remoteUsers-taulukko tyhjä

    if (!remoteUsers || remoteUsers.length === 0) {
      showToast('Vähintään yksi etäkäyttäjä on pakollinen', { type: 'error' });
      setErrorMessages((prev: any) => ({
        ...prev,
        remoteUsers: 'Vähintään yksi etäkäyttäjä on pakollinen!',
      }));
      hasError = true;
    } else {
      // Käy läpi kaikki etäkäyttäjät ja tarkista niiden nimet
      remoteUsers.forEach((user, index) => {
        if (!user.name) {
          showToast(`Etäkäyttäjän ${index + 1} nimi on pakollinen`, {
            type: 'error',
          });

          setErrorMessages((prev: any) => ({
            ...prev,
            [`remoteUserName${index}`]: 'Etäkäyttäjän nimi on pakollinen!',
          }));
          hasError = true;
        }
        if (!user.phone && !user.email) {
          showToast(
            `Etäkäyttäjän ${
              index + 1
            } puhelinnumero tai sähköposti ovat pakollisia`,
            {
              type: 'error',
            }
          );
          setErrorMessages((prev: any) => ({
            ...prev,
            [`remoteUserPhone${index}`]:
              'Etäkäyttäjän puhelinnumero on pakollinen!',
            [`remoteUserEmail${index}`]:
              'Etäkäyttäjän sähköposti on pakollinen!',
          }));
          hasError = true;
        }
      });
    }

    if (hasError) return false;
    return true;

    // validate remoteUsers
  }
}
