import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';
import { useAuth } from '../../../../context/authContext';
import Modal from '../../../../components/modal/modal';
import { useState, useEffect } from 'react';
import Confirmation from '../components/confirmation';
import { NoSites } from '../../../dashboard/components/noSites';

export const Subscriptions = ({
  subcriptions,
  updateData,
}: {
  subcriptions: any;
  updateData: any;
}) => {
  const { currentUser } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [subscriptionIdToCancel, setSubscriptionIdToCancel] = useState<
    string | null
  >(null);

  const promptCancelSubscription = (id: string) => {
    setSubscriptionIdToCancel(id);
    setOpenModal(true);
  };

  const cancelSubscription = async () => {
    if (subscriptionIdToCancel && confirm) {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/cancel-subscription/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${await currentUser.getIdToken()}`,
          },
          body: JSON.stringify({ id: subscriptionIdToCancel }),
        }
      );
      const data = await response.json();
      updateData();
      // Reset states after cancellation
      setOpenModal(false);
      setConfirm(false);
      setSubscriptionIdToCancel(null);
      return data;
    }
  };

  // Watch for changes in the `confirm` state to trigger the cancellation
  useEffect(() => {
    if (confirm && subscriptionIdToCancel) {
      cancelSubscription();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm, subscriptionIdToCancel]);
  if (!subcriptions.length) return <NoSites />;
  return (
    <div>
      <Modal
        show={openModal}
        onClose={() => {
          setOpenModal(false);
          setConfirm(false); // Reset confirm state when modal is closed
        }}
        title="Are you sure?"
      >
        <Confirmation setConfirm={setConfirm} setOpenModal={setOpenModal} />
      </Modal>
      <TableContainer component={Paper}>
        <Table aria-label="subscriptions table">
          <TableHead>
            <TableRow>
              <TableCell>Site</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Camera amount</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Started</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subcriptions.map((sub: any, index: number) => {
              const { basicInfo } = sub;
              if (!basicInfo) return null;

              return (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {basicInfo.name}
                  </TableCell>
                  <TableCell align="right">{sub.status}</TableCell>
                  <TableCell align="right">{sub.quantity}</TableCell>
                  <TableCell align="right">
                    {(sub.quantity * sub.plan.amount) / 100} €/month
                  </TableCell>
                  <TableCell align="right">
                    {new Date(sub.start_date * 1000).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => promptCancelSubscription(sub.id)}
                    >
                      Cancel subscription
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
