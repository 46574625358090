import React, { useState } from 'react';
import { useAuth } from '../../../context/authContext';
import { useToast } from '../../../context/toastContext';
import {
  Button,
  Input,
  Card,
  CardBody,
  Typography,
} from '@material-tailwind/react';

const Register = () => {
  const { registerWithEmailAndPassword } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const { showToast } = useToast();

  const handleRegister = async () => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      showToast(
        'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character'
      );
      return;
    }
    if (password !== confirmPassword) {
      showToast('Passwords do not match, please try again');
      setPasswordError(true);
      return;
    }

    await registerWithEmailAndPassword(email, password);
  };

  return (
    <Card
      placeholder={''}
      className="flex justify-center items-center pb-6 w-full max-w-[600px] "
    >
      <CardBody
        placeholder={''}
        className="flex flex-col  w-full max-w-[500px] p-4"
      >
        <Typography placeholder={''} variant="h4" className="text-center mb-4">
          Register
        </Typography>
        <Typography
          placeholder={''}
          variant="paragraph"
          className="text-center text-gray-600 mb-8"
        >
          Register Account
        </Typography>
        <form>
          <div className="flex flex-col gap-4 mb-4">
            <Input
              type="email"
              color="purple"
              label="Email Address"
              onChange={(e) => setEmail(e.target.value)}
              crossOrigin={true}
            />
            <Input
              type="password"
              color="purple"
              label="Password"
              onChange={(e) => setPassword(e.target.value)}
              error={passwordError}
              crossOrigin={true}
            />
            <Input
              type="password"
              color="purple"
              label="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={passwordError}
              crossOrigin={true}
            />
            {passwordError && (
              <Typography placeholder={''} variant="small" color="red">
                Passwords do not match.
              </Typography>
            )}
            <Button
              placeholder={''}
              color="purple"
              size="lg"
              ripple
              className="w-full mt-4"
              onClick={handleRegister}
            >
              Register
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
};

export default Register;
