import Axios from 'axios';

const api = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getSites = async ({ currentUser }) => {
  //

  const token = await currentUser.getIdToken();
  const sites = await api.get('/user-sites', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      uid: currentUser.uid,
    },
  });

  return await sites.data;
};
