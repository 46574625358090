import React from 'react';
import { Input, Typography } from '@material-tailwind/react';
import { Card } from '@material-tailwind/react';
import { Customer } from '../functions';
//import Input from '@mui/material/Input';
import PersonIcon from '@mui/icons-material/Person';

const BasicInfo = ({
  customer,
  updateCustomer,
  submitCustomer,
  errorMessages,
}: {
  customer: Customer;
  updateCustomer: any;
  submitCustomer: any;
  errorMessages: any;
}) => {
  const inputStyle = {
    /*  backgroundColor: 'white',
    color: 'black',
    width: '100%',
    margin: 'auto',
    padding: '6px',
    borderRadius: '10px', */
  };
  return (
    <Card
      placeholder={''}
      className="bg-primaryBlue p-4 rounded-md shadow-md mb-4"
    >
      <Typography placeholder={''} variant="h4" color="white" className="mb-4">
        Basic site info
      </Typography>
      <div className="mb-4 flex flex-col gap-4 p-8 justify-center items-center w-full ">
        <div className="flex flex-col w-full  justify-center">
          <Input
            required
            crossOrigin={'true'}
            /* error={errorMessages.basicInfo} */
            placeholder={errorMessages.basicInfo ? '' : 'Name'}
            label="Name"
            color="white"
            /* icon={<PersonIcon color="inherit" />} */
            style={inputStyle}
            value={customer.basicInfo.name}
            onChange={(e) =>
              updateCustomer('basicInfo', 'name', e.target.value)
            }
          />
          {errorMessages.basicInfo && (
            <div>
              <Typography
                placeholder={''}
                color="red"
                className=" px-4 pt-2  text-md font-bold  rounded-md"
              >
                {errorMessages.basicInfo}
              </Typography>
            </div>
          )}
        </div>
        <Input
          crossOrigin={'true'}
          color="white"
          label="Street Address"
          placeholder="Street Address"
          style={inputStyle}
          value={customer.basicInfo.street}
          onChange={(e) =>
            updateCustomer('basicInfo', 'street', e.target.value)
          }
        />
        <Input
          crossOrigin={'true'}
          color="white"
          placeholder="City"
          label="City"
          style={inputStyle}
          value={customer.basicInfo.city}
          onChange={(e) => updateCustomer('basicInfo', 'city', e.target.value)}
        />
        <Input
          crossOrigin={'true'}
          label="Postal Code"
          color="white"
          placeholder="Postal Code"
          style={inputStyle}
          value={customer.basicInfo.postalCode}
          onChange={(e) =>
            updateCustomer('basicInfo', 'postalCode', e.target.value)
          }
        />
      </div>
    </Card>
  );
};

export default BasicInfo;
