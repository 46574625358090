import React, { useState } from 'react';
import {
  Input,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from '@material-tailwind/react';

const ResetPassword = ({ resetDialogOpen, setResetDialogOpen }) => {
  const [email, setEmail] = useState('');

  const handleResetPassword = () => {};

  return (
    <div>
      <Dialog size="sm" open={resetDialogOpen} active={true} toggler={() => {}}>
        <DialogHeader>Reset Password</DialogHeader>

        <DialogBody>
          <Typography color="gray" variant="paragraph" className="p-6 pt-0">
            Enter your email address and we will send you a link to reset your
            password.
          </Typography>
          <Input
            type="text"
            color="purple"
            //className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
            label="Email Address"
            style={{ transition: 'all .15s ease' }}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogBody>
        <DialogFooter>
          <div className="flex justify-center items-center gap-6">
            <Button
              color="purple"
              buttonType="link"
              onClick={(e) => {
                e.preventDefault();
                handleResetPassword();
              }}
              ripple="dark"
            >
              Send Reset Link
            </Button>
            <Button
              color="purple"
              onClick={(e) => {
                e.preventDefault();
                setResetDialogOpen(false);
              }}
              ripple="light"
            >
              Cancel
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    </div>
  );
};
export default ResetPassword;
