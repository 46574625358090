import { Input, Typography } from '@material-tailwind/react';

const ContactPerson = ({ customer, updateCustomer, errorMessages }: any) => {
  const Error = ({ message }: any) => {
    if (!message) return null;
    return (
      <div>
        <Typography
          placeholder={''}
          color="red"
          className="mb-4 text-md font-bold   rounded-md"
        >
          {message}
        </Typography>
      </div>
    );
  };

  return (
    <div className="bg-primaryBlue p-4 rounded-md shadow-md mb-4 gap-2">
      <Typography placeholder={''} variant="h4" color="white" className="mb-4">
        Contact person
      </Typography>
      <div className="mb-4">
        <Error message={errorMessages.contactPersonName} />

        <Input
          value={customer.contactPerson.name}
          crossOrigin={true}
          onChange={(e) =>
            updateCustomer('contactPerson', 'name', e.target.value)
          }
          label="Name"
          color="white"
        />
      </div>

      <div className="mb-4">
        <Error message={errorMessages.contactPersonEmail} />

        <Input
          type="email"
          crossOrigin={true}
          color="white"
          value={customer.contactPerson.email}
          onChange={(e) =>
            updateCustomer('contactPerson', 'email', e.target.value)
          }
          label="Email"
        />
      </div>
      <div className="mb-4">
        <Error message={errorMessages.contactPersonPhone} />

        <Input
          type="text"
          crossOrigin={true}
          color="white"
          value={customer.contactPerson.phone}
          onChange={(e) =>
            updateCustomer('contactPerson', 'phone', e.target.value)
          }
          label="Phone number"
        />
      </div>
    </div>
  );
};

export default ContactPerson;
