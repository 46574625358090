import React, { useState, useEffect } from 'react';
import './styles/loadingGear.css';
import { Settings } from 'lucide-react';
import { useCustomer } from '../context/customerContext';
import { Card } from '@material-tailwind/react';

const DotLoader = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 5 ? prevDots + '.' : ''));
    }, 500); // 500ms interval, voit muuttaa nopeutta tarpeen mukaan

    return () => clearInterval(interval);
  }, []);

  return <span>{dots}</span>;
};
const LoadingGear = ({ customState }: any) => {
  const { loadingText } = useCustomer() as any;

  return (
    <Card
      placeholder={''}
      className="flex justify-center items-center p-24 w-full bg-indigo-400/50"
    >
      <div className="flex flex-col justify-center items-center w-full">
        <Settings size={100} className="slow-spin text-darkPurple/80" />
        <div className="text-darkPurple text-xl font-semibold  flex items-start">
          {customState ? customState : loadingText}
          <DotLoader />
        </div>
      </div>
    </Card>
  );
};

export default LoadingGear;
