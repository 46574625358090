import React from 'react';

const CameraConfiguration = ({ camera }: { camera: any }) => {
  const {
    camera: { password, ip: cameraIP, gateway },
  } = camera;

  return (
    <div className="p-4 bg-white shadow-lg rounded-lg overflow-auto max-h-[400px]">
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        Kameran Konfigurointiohjeet
      </h2>
      <ol className="list-decimal list-inside space-y-4">
        <li>Asenna kamera haluttuun paikkaan.</li>
        <li>Kytke kamera virtalähteeseen tai PoE-verkkoon.</li>
        <li>
          Kytke kamera samaan lähiverkkoon jossa konfiguroitu reititin on.
        </li>
        <li>
          Aseta tietokoneesi IP-osoite IP-alueelle 192.168.1.0/24, esim.
          192.168.1.99 ja aliverkon peite 255.255.255.0 (muista asettaa
          takaisin, kun konfigurointi on valmis).
          {/* TODO: Lisää ohjeet IP-osoitteen asettamiseen */}
        </li>
        <li>Avaa selain ja mene osoitteeseen http://192.168.1.108.</li>
        <li>Aseta Country/Region settings haluamaksesi.</li>
        <li>Hyväksy käyttöehdot.</li>
        <li>Aseta aika ja aikavyöhyke.</li>
        <li>
          Aseta kameran salasanaksi{' '}
          <span className="font-bold">{password}</span> ja sähköpostiosoitteeksi
          osoite, jolla voit myöhemmin resetoida kameran salasanan.
        </li>

        <li>P2P-asetukset voit jättää oletusarvoiksi -&gt; Next.</li>
        <li>Päivitysasetukset voit jättää oletusarvoiksi -&gt; Save.</li>
        <li>
          Kirjaudu kameraan antamillasi tunnuksilla, käyttäjätunnus on{' '}
          <strong>admin</strong> ja salasana on <strong>{password}</strong>.
        </li>
        <li>Valitse settings -&gt; Network -&gt; TCP/IP.</li>
        <li>
          Aseta IP-Address kameran IP-osoite{' '}
          <span className="font-bold">{cameraIP}</span> ja aliverkon peitteeksi
          255.255.255.0 sekä Gatewayksi{' '}
          <span className="font-bold">{gateway}</span> -&gt; Save.
        </li>

        <li>
          Voit sulkea selaimen ja kamera on nyt valmis käytettäväksi ja tulee
          näkymään etäohjelmaan hetken kuluttua.
        </li>
      </ol>
    </div>
  );
};

/* 

Ohjeet kameran konfigurointiin
1. Asenna kamera haluttuun paikkaan
2. Kytke kamera virtalähteeseen tai PoE-verkkoon
3. Kytke kamera samaan lähiverkkoon jossa tietokoneesi ja konfiguroitu reititin on

4. Aseta tietokoneesi IP-osoite IP alueelle 192.168.1.0/24 esim. 192.168.1.99 ja aliverkon peite 255.255.255.0 (muista asettaa takaisin kun konfigurointi on valmis)
//TODO: Lisää ohjeet IP-osoitteen asettamiseen
5. Avaa selain ja mene osoitteeseen http://192.168.1.108
6. Aseta Country/Region settings haluamaksesi
7. Hyväksy käyttöehdot
8. Aseta aika ja aikavyöhyke 
9. Aseta kameran salasanaksi ${password} ja sähköpostiosoitteeksi osoite jolla voit myöhemmin resetoida kameran salasanan
10. P2P asetukset voit jättää oletusarvoiksi -> Next
11. Päivitysasetukset voit jättää oletusarvoiksi -> Save

12. Kirjaudu kameraan antamillasi tunnuksilla, käyttäjätunnus on admin ja salasana on ${password}
13. Valitse settings -> Network -> TCP/IP
14. Aseta IP-Address kameran IP-osoite ${cameraIP} ja aliverkon peitteeksi 255.255.255.0 sekä Gatewayksi ${gateway} -> Save
15. Voit sulkea selaimen ja kamera on nyt valmis käytettäväksi ja tulee näkymään etäohjelmaan hetken kuluttua.

*/

export default CameraConfiguration;
